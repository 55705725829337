.align-create-btn-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.grid_custom_res {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}